<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <div class="gra">
    <div
      class="max-w-2xl mx-auto text-center py-16 px-4 sm:py-20 sm:px-6 lg:px-8"
    >
      <h2 class="text-3xl font-extrabold text-white sm:text-4xl">
        <span class="block">404</span>
        <span class="block">Page Not Found.</span>
      </h2>
      <router-link
        to="/"
        class="bh mt-8 w-full inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-gray-900 bg-white hover:bg-indigo-50 sm:w-auto"
      >
        Back to home page
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "FourOhFour",
};
</script>
