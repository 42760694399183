<template>
  <div>
    <FourOhFour />
  </div>
</template>

<script>
import FourOhFour from "../components/other/FourOhFour.vue";

export default {
  components: {
    FourOhFour,
  },
};
</script>
